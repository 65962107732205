<template lang="pug">
  Modal.modal-content-collections(v-bind="$attrs")
    p This content is in #[strong {{ collections.length }}] {{ collections.length === 1 ? 'collection' : 'collections' }}:
    ul
      li(v-for="(collection, i) in collections", :key="i")
        router-link(:to="{name: 'collection', params: { type: collection.type, id: collection.id }}", target="_blank")
          Icon(:icon="collectionIcon(collection.type)")
          span {{ collection.label }}
</template>

<script>
export default {
  props: {
    collections: {
      type: Array,
      required: true
    }
  },
  methods: {
    collectionType(collectionType) {
      const typeMap = {
        partner: "partner",
        user: "personal"
      }

      return typeMap[collectionType]
    },
    collectionIcon(collectionType) {
      const iconMap = {
        partner: "users",
        user: "user"
      }

      return iconMap[collectionType]
    }
  }
}
</script>

<style>
.modal-content-collections {
  ul {
    @apply mt-6;
  }

  li + li {
    @apply mt-4 border-t border-dotted pt-4;
  }

  a {
    @apply flex;
  }

  .icon {
    @apply mr-3 mt-1;
  }
}
</style>
