<template>
  <div :class="`ag-block ag-block--${type}`">
    <div class="w-full md:flex">
      <div class="mr-6 flex-shrink-0">
        <div class="ag-block__icon">
          <Icon :icon="icon" icon-prefix="far" />
        </div>
      </div>
      <div class="w-full">
        <slot />
      </div>
    </div>
    <BlockControls
      v-if="showControls"
      :type="type"
      :id="id"
      :searchable="searchable"
      :has_customisations="has_customisations"
      :collections="collections"
    />
  </div>
</template>

<script>
import BlockControls from "./components/BlockControls.vue"

export default {
  components: {
    BlockControls
  },
  props: {
    type: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    searchable: {
      type: Boolean,
      required: true
    },
    has_customisations: {
      type: Boolean,
      default: false
    },
    collections: {
      type: Array,
      default: () => []
    },
    showControls: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.ag-block {
  &__title {
    @apply text-lg;
  }
}
</style>
