<template>
  <div class="ag-block-controls flex space-x-3">
    <div v-if="showHasCustomisations"><CustomisationsIndicator /></div>
    <div v-if="collections.length"><InCollectionsIndicator :id="id" :collections="collections" /></div>
    <div v-if="type === 'content'"><ToggleInCartButton :id="id" /></div>
    <div v-if="showExtraActions"><BlockExtraActions :type="type" :id="id" :searchable="searchable" /></div>
  </div>
</template>

<script>
import BlockExtraActions from "./BlockExtraActions.vue"
import CustomisationsIndicator from "./CustomisationsIndicator.vue"
import InCollectionsIndicator from "./InCollectionsIndicator.vue"
import ToggleInCartButton from "./ToggleInCartButton.vue"

export default {
  components: {
    BlockExtraActions,
    CustomisationsIndicator,
    InCollectionsIndicator,
    ToggleInCartButton
  },
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    searchable: {
      type: Boolean,
      required: true
    },
    has_customisations: {
      type: Boolean,
      default: false
    },
    collections: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showExtraActions() {
      return ["collection", "content"].includes(this.$route.name)
    },
    showHasCustomisations() {
      return this.type === "content" && this.has_customisations
    }
  }
}
</script>

<style>
.ag-block-controls {
  .button {
    @apply rounded;

    &--icon-only {
      @apply flex h-10 w-10 items-center justify-center;
    }
  }

  .dropdown-trigger {
    @apply flex h-10;
  }
}
</style>
