<template>
  <div class="in-collections-indicator" @click="showCollections()">
    <div class="flex h-10 items-center space-x-2">
      <Icon icon="collection" prefix="fas" class="text-supplement" />
      <span>{{ collections.length }}</span>
    </div>
    <div class="in-collections-indicator__popover">
      <p>
        Currently in <strong>{{ collections.length }}</strong
        >{{ $route.name === "collection" ? " other " : " "
        }}{{ collections.length === 1 ? "collection" : "collections" }}:
      </p>
      <ul>
        <li v-for="(collection, i) in collections" :key="i">
          <router-link
            :to="{ name: 'collection', params: { id: collection.id } }"
            target="_blank"
            :class="`collection-${collection.type}`"
          >
            <Icon icon="collection" :style="collection.type === 'partner' ? `color: ${brandColor};` : ''" />
            <span>{{ collection.label }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ContentCollectionsModal from "../../Content/ContentCollectionsModal.vue"

export default {
  props: {
    collections: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    brandColor() {
      return this.user.get("partner.brand_color")
    }
  },
  methods: {
    showCollections() {
      if (window.outerWidth < 768) {
        this.$modal.show(ContentCollectionsModal, { collections: this.collections })
      }
    }
  }
}
</script>

<style>
.in-collections-indicator {
  @apply relative cursor-default;

  &__popover {
    @apply absolute left-1/2 z-10 hidden -translate-x-1/2 overflow-hidden overflow-y-auto rounded border bg-white shadow;
    max-height: 500px;
    width: 350px;

    > p {
      @apply mx-6 my-4;
    }

    li {
      @apply border-t border-dotted;
    }

    a {
      @apply flex px-6 py-4;

      &:hover {
        @apply bg-primary-50;
      }
    }

    .collection-personal .icon {
      @apply text-supplement;
    }

    .icon {
      @apply mr-3 mt-1;
    }
  }

  &:hover {
    ^&__popover {
      @apply block;
    }
  }
}
</style>
