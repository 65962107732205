<template>
  <div class="ag-block-extra-actions">
    <Dropdown icon="ellipsis-h" icon-prefix="fas" class="flip-menu">
      <DropdownLink v-if="showEdit" icon="pencil" @click="edit">Edit {{ editLabel }}</DropdownLink>
      <DropdownLink v-if="showCustomize" icon="crown" @click="customizeBlock">Customize {{ editLabel }}</DropdownLink>
      <DropdownLink v-if="showDuplicate" icon="clone" @click="duplicateBlockInCollection"
        >Duplicate {{ editLabel }}</DropdownLink
      >
      <DropdownHorizontalRule v-if="showDivider" />
      <DropdownLink v-if="showRemoveFromCollection" @click="removeBlockFromCollection()" icon="times">
        <span>Remove from collection</span>
      </DropdownLink>
    </Dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const CustomizeContentBlockModal = () => import("@/modals/Blocks/Content/CustomizeContentBlockModal.vue")

const EditCTABlockModal = () => import("@/modals/Blocks/CTA/EditCTABlockModal.vue")
const EditHeadingBlockModal = () => import("@/modals/Blocks/Heading/EditHeadingBlockModal.vue")
const EditImageBlockModal = () => import("@/modals/Blocks/Image/EditImageBlockModal.vue")
const EditItineraryBlockModal = () => import("@/modals/Blocks/Itinerary/EditItineraryBlockModal.vue")
const EditNoteBlockModal = () => import("@/modals/Blocks/Note/EditNoteBlockModal.vue")
const EditTextBlockModal = () => import("@/modals/Blocks/Text/EditTextBlockModal.vue")

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    searchable: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    editLabel: ""
  }),
  created() {
    const editLabels = {
      cta: "call to action"
    }

    this.editLabel = editLabels[this.type] || this.type
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    showDuplicate() {
      if (this.type === "content" || (this.type === "image" && this.searchable)) {
        return false
      }

      return this.user.hasPermission(`blocks.${this.type}`)
    },
    showCustomize() {
      return (
        this.$route.name === "collection" &&
        (this.type === "content" || (this.type === "image" && this.searchable)) &&
        this.user.hasPermission("advanced.customise_content") &&
        (this.user.partner.has_premium_seo || this.user.internal)
      )
    },
    showEdit() {
      return (
        (this.$route.name === "content" && this.type === "content") ||
        (this.user.hasPermission(`blocks.${this.type}`) && !this.searchable)
      )
    },
    showDivider() {
      return this.$route.name === "collection" && (this.showEdit || this.showCustomize || this.showDuplicate)
    },
    showRemoveFromCollection() {
      return this.$route.name === "collection"
    }
  },
  methods: {
    async customizeBlock() {
      const componentMap = {
        content: CustomizeContentBlockModal
      }

      let collection_id = this.$route.params.id

      let response = await this.$modal.show(componentMap[this.type], {
        block_id: this.id,
        collection_id: Number(collection_id)
      })

      if (response) {
        this.$store.dispatch("collection/updateBlock", response.data)
      }
    },
    async edit() {
      if (this.type === "content") {
        this.$router.push({ name: "content.edit", params: { id: this.id } })
        return
      }

      const componentMap = {
        cta: EditCTABlockModal,
        heading: EditHeadingBlockModal,
        image: EditImageBlockModal,
        itinerary: EditItineraryBlockModal,
        note: EditNoteBlockModal,
        text: EditTextBlockModal
      }

      let collection_id = this.$route.params.id

      let response = await this.$modal.show(componentMap[this.type], {
        block_id: this.id,
        collection_id: Number(collection_id) || null
      })

      if (collection_id && response) {
        this.$store.dispatch("collection/updateBlock", response.data)
      }
    },
    async duplicateBlockInCollection() {
      const collectionId = this.$route.params.id
      const blockId = this.id

      let response = await this.$api.get(`collections/${collectionId}/blocks/${blockId}/duplicate`)

      if (response) {
        this.$store.dispatch("collection/find", collectionId)
        this.$notification.success("Got it!")
      }
    },
    async removeBlockFromCollection() {
      let block_id = this.id
      let collection_id = this.$route.params.id

      await this.$api.put(`collections/${collection_id}/remove-block`, { block_id })
      this.$store.dispatch("collection/deleteBlock", block_id)
      this.$notification.success("Got it!")
    }
  }
}
</script>

<style>
.ag-block-extra-actions {
  @apply ml-1;

  .dropdown-menu {
    width: 225px;
  }
}
</style>
