<template>
  <div class="toggle-in-cart-button">
    <Button v-if="!inCart" type="primary" prepend-icon="plus" @click="addToCart" />
    <Button
      v-else
      type="danger"
      :prepend-icon="inCartButton"
      @click="removeFromCart"
      @mouseover="inCartButton = 'times'"
      @mouseleave="inCartButton = 'shopping-bag'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    inCartButton: "shopping-bag"
  }),
  computed: {
    ...mapGetters({
      find: "cart/find"
    }),
    inCart() {
      return this.find(this.id)
    }
  },
  methods: {
    async addToCart() {
      this.$store.dispatch("cart/add", this.id)
    },
    removeFromCart() {
      this.$store.dispatch("cart/remove", this.id)
    }
  }
}
</script>

<style>
.toggle-in-cart-button {
  @apply pl-2;

  .button.danger {
    @apply border-secondary bg-secondary;

    &:hover {
      @apply border-red-600 bg-red-600;
    }
  }
}
</style>
