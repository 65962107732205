<template lang="pug">
  div.sidebar-widget
    slot(name="title")
      div.sidebar-widget__header
        h3.sidebar-widget__title(v-if="title") {{ title }}
        div(v-if="newFeature"): span.sidebar-widget__badge NEW
    div.sidebar-widget__body
      slot
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    newFeature: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.sidebar-widget {
  & + & {
    @apply mt-12;
  }

  &__header {
    @apply mb-5 flex border-b-2 pb-3;
  }

  &__badge {
    @apply ml-2 flex-none rounded-md bg-secondary px-2 py-1 text-xs font-semibold leading-none tracking-wide text-white;
  }
}
</style>
